.aws-btn {
    --slider-height-percentage: 45%;

    --slider-transition-duration: 80ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 20px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #2d5182;
    --control-bullet-active-color: #26456f;
    }

